<template>
  <div class="updatePWD-contain">
    <div class="tel-code-container" v-if="type == 1">
      <div class="tel-code-title">您正在设置签署密码</div>
      <div class="tel-code-phone">
        <span class="text">已发送验证码到</span>
        <span class="text bold">{{ loginModel.account }}</span>
      </div>
      <div
        class="tel-code-input"
        ref="telCodeInput"
        @click="handleFocusLoginCodeInput"
      >
        <span
          class="tel-code-val"
          :class="{ highlight: isCurInputSpan(index) }"
          v-for="(item, index) in 6"
          :key="index"
        >
          {{ charAtCode(loginModel.code, index) }}
        </span>
        <input
          class="login-code-input"
          type="tel"
          maxlength="6"
          inputmode="numeric"
          ref="loginCodeInput"
          @input="onChange"
        />
      </div>
      <div class="code-label code-label-btn" v-if="!isSend" @click="sendCode">
        获取验证码
      </div>
      <div class="code-label" v-else>重新发送（{{ num }}）</div>
    </div>
    <template v-else>
      <div class="header-type">
        <div class="item" :class="{ active: type == 2, finish: type == 3 }">
          <div class="num">
            <template v-if="type == 2">1</template>
            <template v-else>
              <img src="@/assets/imgs/hook.svg" alt="" />
            </template>
          </div>
          <div class="label">设置密码</div>
        </div>
        <div class="line"></div>
        <div class="item" :class="{ active: type == 3 }">
          <div class="num">2</div>
          <div class="label">确认密码</div>
        </div>
      </div>
      <template v-if="type == 2">
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :length="6"
          info="密码为 6 位数字"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="value"
          :show="showKeyboard"
          @blur="showKeyboard = false"
        />
      </template>
      <template v-if="type == 3">
        <!-- 密码输入框 -->
        <van-password-input
          :value="value2"
          :length="6"
          info="密码为 6 位数字"
          :error-info="errorInfo"
          :focused="showKeyboard2"
          @focus="showKeyboard2 = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="value2"
          :show="showKeyboard2"
          @blur="showKeyboard2 = false"
        />
      </template>
    </template>
    <van-dialog
      v-model="show"
      class="view-dialog"
      confirmButtonText="知道了"
      @confirm="goDetail"
    >
      <img
        class="dialog-icon"
        src="@/assets/imgs/account/success2.png"
        alt=""
      />
      <div class="dialog-title">交易密码修改成功</div>
    </van-dialog>
  </div>
</template>

<script>
  import Api from '@/api'
  import { mapState, mapGetters } from 'vuex'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        type: 1, // 1获取验证码 2输入密码 3确认密码
        loginModel: {
          account: undefined,
          code: undefined,
          openId: window.sessionStorage.openId
            ? window.sessionStorage.openId
            : null,
        },
        value: '',
        showKeyboard: true,
        value2: '',
        showKeyboard2: true,
        errorInfo: '',
        num: 60,
        isSend: false,
        show: false,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        showSignPassword: state => state.contractSign.showSignPassword,
      }),
    },
    watch: {
      value(value) {
        if (value.length === 6) {
          this.type = 3
        }
      },
      value2(value) {
        if (value.length === 6 && value !== this.value) {
          this.errorInfo = '两次密码输入不一致'
        } else if (value.length === 6 && value === this.value) {
          this.errorInfo = ''
          this.confirm()
        } else if (value.length <= 6) {
          this.errorInfo = ''
        }
      },
    },
    created() {
      this.loginModel.account = this.userInfo.account
      this.sendCode()
    },
    mounted() {
      this.$refs.loginCodeInput.focus()
    },
    methods: {
      sendCode() {
        Api.getTelCode({
          telephone: this.loginModel.account,
          type: 15,
        }).then(res => {
          this.setCode()
        })
      },
      setCode() {
        this.isSend = true
        this.timer = setInterval(() => {
          if (!this.num) {
            clearInterval(this.timer)
            this.num = 60
            this.isSend = false
            return
          }
          this.num -= 1
        }, 1000)
      },
      confirm() {
        const obj = {
          value: this.value,
          value2: this.value2,
        }
        this.onUpdateSignPwd()
      },
      // 更换签约密码
      onUpdateSignPwd(newPwd, code) {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        Api.updateSignPwd({
          code: this.loginModel.code,
          newPwd: this.value,
        })
          .then(res => {
            this.$store.dispatch('fetchUserInfo')
            this.show = true
          })
          .catch(err => {
            this.errorInfo = err.msg
          })
          .finally(() => {
            this.toast.clear()
          })
      },
      goDetail() {
        window.history.back()
      },
      // 截取code中的字符
      charAtCode(code, index) {
        if (code) {
          return code.toString()[index]
        }
        return undefined
      },
      // 输入验证码框 是否为当前的span
      isCurInputSpan(index) {
        if (this.loginModel.code) {
          const len = this.loginModel.code.toString().length
          if (index + 1 === len) {
            return true
          }
        } else {
          if (index === 0) {
            return true
          }
        }

        return false
      },
      // 让输入框聚焦
      handleFocusLoginCodeInput() {
        console.log('让输入框聚焦');
        this.$nextTick(() => {
          const { loginCodeInput } = this.$refs
          loginCodeInput.focus()
        })
      },
      async onChange(e) {
        const { target } = e
        const { value } = target
        this.loginModel.code = value
        if (this.loginModel.code.length === 6) {
          Api.checkTelCode({
            telCode: this.loginModel.code,
          })
            .then(res => {
              target.blur()
              this.type = 2
            })
            .catch(err => {
              this.$toast(err.msg)
              this.$refs.loginCodeInput.focus()
            })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .updatePWD-contain {
    padding-top: 95px;
    .tel-code-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tel-code-title {
        font-size: 22px;
        line-height: 30px;
        color: #333;
        font-weight: 500;
      }
      .tel-code-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          margin-top: 30px;
          font-size: 18px;
          line-height: 22px;
          color: #434751;
          &.bold {
            font-weight: 500;
          }
        }
        .text + .text {
          margin-top: 7px;
        }
      }
      .tel-code-input {
        margin-top: 68px;
        transition: all 0.1s;

        display: flex;
        .tel-code-val {
          display: inline-flex;
          justify-content: center;
          width: 33px;
          height: 33px;
          font-size: 24px;
          border-bottom: 1px solid #eee;
          transition: all 0.1s;

          &.highlight {
            border-bottom: 1px solid #434751;
          }
        }
        .tel-code-val + .tel-code-val {
          margin-left: 17px;
        }
        .login-code-input {
          position: absolute;
          display: flex;
          left: -100px;
          top: -100px;
        }
      }
      .code-label {
        font-size: 16px;
        color: #8a9ab4;
        margin-top: 22px;
      }
      .code-label-btn {
        color: #1676ff;
      }
      .get-code {
        margin-top: 22px;
        font-size: 16px;
        color: @BLUE;

        &.disabled {
          color: @TEXT-COLOR-2;
        }
      }
    }
    .header-type {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 68px;
        .num {
          box-sizing: border-box;
          margin: 0 auto;
          width: 35px;
          height: 35px;
          background: #fff;
          border: 1px solid #8a9ab4;
          color: #8a9ab4;
          border-radius: 50%;
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #8a9ab4;
        }
        .label {
          width: 100%;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #404c56;
          line-height: 22px;
        }
      }
      .active {
        .num {
          background: #1676ff;
          border: 1px solid #1676ff;
          color: #fff;
        }
        .label {
          color: #1676ff;
        }
      }
      .finish {
        .num {
          border: 2px solid #1676ff;
        }
      }
      .line {
        width: 106px;
        height: 1px;
        border: 1px solid #c1c4cd;
        margin-top: -35px;
      }
    }
  }
  .view-dialog {
    /deep/ .van-dialog__content {
      text-align: center;
      padding: 29px;
      .dialog-icon {
        width: 66px;
        margin-bottom: 27px;
      }
      .dialog-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #111a34;
        line-height: 20px;
      }
    }
  }
</style>
